import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
            <Helmet>
        <meta charSet="utf-8" />
        <title>30 Day Map Challenge</title>
        <meta name="description" content="My gallery of past daily challenge entries." />
        <meta name="image" content="https://awmcphee.ca/30dmc.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/30dmc.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="30 Day Map Challenge"/>
        <meta name="twitter:description" content="My gallery of past daily challenge entries."/>
        <meta name="twitter:image" content="https://awmcphee.ca/30dmc.jpg"/>
      </Helmet>
      <h1>30 Day Map Challenge</h1>
      <h2>It's on!</h2>
      <p>Every November, the cartographic community braces itself for the grueling <a href="https://github.com/tjukanovt/30DayMapChallenge" target="_blank" rel="noopener noreferrer" className="plainlink">30 Day Map Challenge</a>.</p>
      <p>As a freelancer, I really enjoy the opportunity to test my skills. These are my daily submissions from past years.</p>
      <h2>2021</h2>
      <div className="portfolio">
      <div className="pfs">
      <a href="/1906_population.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/01 1906 Census.jpg"
            alt="A map of the rural population of Canada's prairie provinces, circa 1906."
          /></a>
          <h4>1. Points</h4>
          </div>
        <div className="pfs">
        <a href="/peace_river_crossing.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/02 Peace.jpg"
            alt="A map of the railway crossing at Peace River, Alberta."
          />
          </a>
          <h4>2. Lines</h4>
        </div>
        <div className="pfs">
        <a href="/3_nearest_park.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/3_nearest_park.jpg"
            alt="A map of the nearest National Park of Canada to any point in the country."
          />
          </a>
          <h4>3. Polygons</h4>
        </div>
        <div className="pfs">
        <a href="/4_hex_population.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/4_hex_population.jpg"
            alt="A hexagonally binned map of Canada's population density."
          />
          </a>
          <h4>4. Hexagons</h4>
        </div>
        <div className="pfs">
        <a href="/5_inuvik_utilidors.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/5_inuvik_utilidors.jpg"
            alt="A map of Inuvik's elevated utility corridors."
          />
          </a>
          <h4>5. OpenStreetMap</h4>
        </div>
        <div className="pfs">
        <a href="/6_nb_1987.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/6_nb_1987.png"
            alt="A map of the 1987 New Brunswick Liberal landslide."
          />
          </a>
          <h4>6. Red</h4>
        </div>
        <div className="pfs">
        <a href="/7_pei_2019.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/7_pei_2019.png"
            alt="A map of the 2019 Prince Edward Island provincial election, and its impressive Green performance."
          />
          </a>
          <h4>7. Green</h4>
        </div>
        <div className="pfs">
        <a href="/8_ab_1982.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/8_ab_1982.png"
            alt="A map of the 1982 Alberta provincial election, a Conservative landslide."
          />
          </a>
          <h4>8. Blue</h4>
        </div>
        <div className="pfs">
        <a href="/9_monochrome_election.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/9_monochrome_election.jpg"
            alt="An uncoloured map showing the 2021 Canadian election with shapes and patterns."
          />
          </a>
          <h4>9. Monochrome</h4>
        </div>
        <div className="pfs">
        <a href="/10_light_and_livelihood.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/10_light_and_livelihood.jpg"
            alt="A map of night lights in Alberta."
          />
          </a>
          <h4>10. Raster</h4>
        </div>
        <div className="pfs">
        <a href="/11_elevator_view.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/11_elevator_view.jpg"
            alt="A map of grain elevators and sightlines at their historical peak in Saskatchewan."
          />
          </a>
          <h4>11. 3D</h4>
        </div>
        <div className="pfs">
        <a href="/12_population_centres.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/12_population_centres.jpg"
            alt="A map of the centres of population for every census ethnicity in Canada."
          />
          </a>
          <h4>12. Population</h4>
        </div>
        <div className="pfs">
        <a href="/13_hutterites.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/13_hutterites.jpg"
            alt="A map of every Hutterite colony in North America."
          />
          </a>
          <h4>13. Natural Earth</h4>
        </div>
        <div className="pfs">
        <a href="/nc" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/14_nc.png"
            alt="An interactive map of New Columbia, a fictional country."
          />
          </a>
          <h4>14. Map with a new tool</h4>
        </div>
        <div className="pfs">
        <a href="/15_no_computer.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/15_no_computer.jpg"
            alt="A handmade paper map of the highest point in every province."
          />
          </a>
          <h4>15. Map made without using a computer</h4>
        </div>
        <div className="pfs">
        <a href="/16_urban_rural.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/16_urban_rural.jpg"
            alt="A map showing the smallest circle that encloses 50% of Canada's population."
          />
          </a>
          <h4>16. Urban/rural</h4>
        </div>
        <div className="pfs">
        <a href="/17_yukon.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/17_yukon.jpg"
            alt="A map showing First Nations settlement lands in Yukon."
          />
          </a>
          <h4>17. Land</h4>
        </div>
        <div className="pfs">
        <a href="/18_reindeer.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/18_reindeer.jpg"
            alt="A map showing Reindeer Lake, Canada."
          />
          </a>
          <h4>18. Water</h4>
        </div>
        <div className="pfs">
        <a href="/19_eez.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/19_eez.png"
            alt="A map showing the territorial waters of the Commonwealth realms."
          />
          </a>
          <h4>19. Island(s)</h4>
        </div>
        <div className="pfs">
        <a href="/20_average_life.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/20_average_life.jpg"
            alt="A map showing the shifting centre of population for each age group in Canada."
          />
          </a>
          <h4>20. Movement</h4>
        </div>
        <div className="pfs">
        <a href="/21_sumas.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/21_sumas.jpg"
            alt="A map showing the Sumas Lake basin in the Fraser Valley, British Columbia."
          />
          </a>
          <h4>21. Elevation</h4>
        </div>
        <div className="pfs">
        <a href="/22_nwt.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/22_nwt.jpg"
            alt="A map showing the results of the boundary plebiscites in the Northwest Territories."
          />
          </a>
          <h4>22. Boundaries</h4>
        </div>
        <div className="pfs">
        <a href="/23_central_asia.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/23_central_asia.png"
            alt="A map showing the population density and borders of Central Asia."
          />
          </a>
          <h4>23. GHSL</h4>
        </div>
        <div className="pfs">
        <a href="/24_plebiscites.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/24_plebiscites.png"
            alt="A map showing the 1991 election results in Saskatchewan."
          />
          </a>
          <h4>24. Historical map</h4>
        </div>
        <div className="pfs">
        <a href="/nc_2020" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/25_nc.png"
            alt="An interactive map of New Columbia, a fictional country."
          />
          </a>
          <h4>25. Interactive map</h4>
        </div>
        <div className="pfs">
        <a href="/26_income.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/26_income.png"
            alt="A map showing median household income in Canada."
          />
          </a>
          <h4>26. Choropleth map</h4>
        </div>
        <div className="pfs">
          <a href="/27_weather.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/27_weather.jpg"
            alt="A map showing annual temperature in Canada."
          />
          </a>
          <h4>27. Heatmap</h4>
        </div>
        <div className="pfs">
        <a href="/28_geoid.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/28_geoid.jpg"
            alt="A map showing the elevation differences of the geoid, which is the shape of the Earth if it were all covered by water."
          />
          </a>
          <h4>28. The Earth is not flat</h4>
        </div>
        <div className="pfs">
        <a href="/29_didnt_vote.png" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/29_didnt_vote.png"
            alt="A map showing Canada's 2021 election, if people who didn't vote were counted as a political party."
          />
          </a>
          <h4>29. Null</h4>
        </div>
        <div className="pfs">
        <a href="/30_alberta.jpg" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../30dmc/30_alberta.jpg"
            alt="The sales volume of Alex's Alberta wall map in 2021."
          />
          </a>
          <h4>30. Metamapping day</h4>
        </div>
        </div>
      <h2>2020</h2>
      <p>All of these images can be right-clicked and opened in a new tab.</p>
      <div className="portfolio">
        <div className="pfs">
          <StaticImage
            src="../30dmc/01 Sask Points.png"
            alt="A map of population centres in Saskatchewan."
          />
          <h4>1. Points</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/02 Sask Lines.png"
            alt="A map of population centres and railways in Saskatchewan."
          />
          <h4>2. Lines</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/03 Polygons.png"
            alt="A map of informal cultural regions in Saskatchewan."
          />
          <h4>3. Polygons</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/04 Canada.png"
            alt="A hexagonally binned population map of Canada."
          />
          <h4>4. Hexagons</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/05 Flax Production.jpg"
            alt="A map of flax production in Saskatchewan."
          />
          <h4>5. Blue</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/06 Red River Valley.jpg"
            alt="A map of the Red River Valley."
          />
          <h4>6. Red</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/07 Green.png"
            alt="A map of how many beer can deposits are needed to purchase a house."
          />
          <h4>7. Green</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/08 Yellow Canola.jpg"
            alt="A map of canola acreage in Western Canada."
          />
          <h4>8. Yellow</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/09 Val Marie.jpg"
            alt="A dot-matrix style map of Val Marie and its surroundings."
          />
          <h4>9. Monochrome</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/10 Jefferson's Grid.jpg"
            alt="A statistical map of the measurement error in the United States Public Land Survey System."
          />
          <h4>10. Grid</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/11 3D.jpg"
            alt="A map of the viewshed from the roof of Val Marie's grain elevator."
          />
          <h4>11. 3D</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/12 The United Counties of Last Day and Radium.png"
            alt="A map showing the municipalities and election results of a fictional county."
          />
          <h4>12. Map not made with GIS software</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/13 Tree Species.jpg"
            alt="A map of tree species prevalence in Alberta."
          />
          <h4>13. Raster</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/14 Mackenzie County.jpg"
            alt="A map of new farms cleared in Mackenzie County since 1990."
          />
          <h4>14. Climate change</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/15 Nunavut MLAs.png"
            alt="A map of community populations in Nunavut."
          />
          <h4>15. Connections</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/16 Island Forests.jpg"
            alt="A map of Saskatchewan's island forests."
          />
          <h4>16. Island(s)</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/17 Historical SDs.jpg"
            alt="A map of historical country school districts in Alberta."
          />
          <h4>17. Historical map</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/18 Land Cover.jpg"
            alt="A diagram of Alex's favourite land cover colour scheme."
          />
          <h4>18. Landuse</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/19 Alberta Field Check.jpg"
            alt="A map of roads in Alberta that Alex has personally driven."
          />
          <h4>19. <em>NULL</em></h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/20 Population FNMI.png"
            alt="A map of Canada's Indigenous population."
          />
          <h4>20. Population</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/21 The Delta.jpg"
            alt="A map of temporal water coverage in the Peace-Athabasca Delta."
          />
          <h4>21. Water</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/22 Air Service North.png"
            alt="A map of air service to the Canadian Territories as of November 2020."
          />
          <h4>22. Movement</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/23 Mackenzie County.png"
            alt="A map of the malapportioned ward boundaries in Mackenzie County, Alberta."
          />
          <h4>23. Boundaries</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/24 Saskatchewan.jpg"
            alt="A map of landforms and elevation in Saskatchewan."
          />
          <h4>24. Elevation</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/25 coroma.png"
            alt="A map of covid rates in Western Canada, November 24, 2020."
          />
          <h4>25. COVID-19</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/26 Saskatchewan Grid Cartogram.png"
            alt="A map of the Rural Municipality of Auvergne No. 76."
          />
          <h4>26. Map with a new tool</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/27 I Voted.png"
            alt="A diagram of Alex's first vote in a Canadian federal election."
          />
          <h4>27. Big or small data</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/28 NWT Cartogram.png"
            alt="A cartogram of white and Indigenous population in the Northwest Territories."
          />
          <h4>28. Non-geographic map</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/29 NZ Population Spheres.png"
            alt="A map of populated places in New Zealand."
          />
          <h4>29. Globe</h4>
        </div>
        <div className="pfs">
          <StaticImage
            src="../30dmc/30 Peace Country.jpg"
            alt="A map of the Peace River Country."
          />
          <h4>30. A map</h4>
        </div>
        </div>
    </Layout>
  );
}
